<template>
  <div class="wrapper">
    <menu-ver type="company" name="Company" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
          :style="{ backgroundColor: colorCompany }"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="headquarters && headquarters.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("mCompany.headquarters.name") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in headquarters" :key="item.sede_id">
            <td>{{ item.sede_nombre }}</td>
            <td>
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                :style="{ backgroundColor: colorCompany }"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("mCompany.headquarters.addTitle", { name: `${headquarterId}` })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{$t(`mCompany.headquarters.name`) + ' *'}}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                id="FormName"
                v-model.trim="sede_nombre"
                :placeholder="$t(`mCompany.headquarters.name`) + '*'"
                maxlength="255"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addHeadquarter"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("mCompany.headquarters.deleteTitle", {
                name: `${headquarterId}`,
              })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("mCompany.headquarters.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteHeadquarter"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              $t("mCompany.headquarters.editTitle", {
                name: `${headquarterId}`,
              })
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{$t(`mCompany.headquarters.name`) + ' *'}}</label>
              <input
                type="text"
                class="form-control"
                style="text-transform: uppercase"
                id="exampleDropdownFormName"
                v-model.trim="sede_nombre"
                :placeholder="$t(`mCompany.headquarters.name`) + '*'"
                maxlength="255"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editHeadquarter"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      headquarters: [],
      headquarter: {
        sede_id: "",
        sede_nombre: "",
      },
      sede_nombre: "",
      headquarterId: "",
      result: "",
      action: 0,
      id: "",
      formError: false,
      loading: false,
    };
  },
  created() {
    this.getHeadquarters();
    this.changeBreadCrumb(14);
    this.bigDrop();
  },
  computed: {
    ...mapState(["colorCompany","servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getHeadquarters() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=sede&ac=listado`
        );
        if (res.data) {
          this.headquarters = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addHeadquarter() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const headquarter = {
            sede_nombre: this.sede_nombre.toUpperCase(),
          };
          const res = await axios.post(
            `${this.servidor}api/op_emp.asp?tp=sede&ac=grabar`,
            headquarter
          );
          
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async editHeadquarter() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.loading = true;
          const headquarter = {
            sede_nombre: this.sede_nombre.toUpperCase(),
            sede_id: this.headquarter.sede_id
          };
          
          const res = await axios.post(
            `${this.servidor}api/op_emp.asp?tp=sede&ac=actualizar`,
            headquarter
          );
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
          this.result = "1";
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        }
        this.loading = false;
      }
    },
    async deleteHeadquarter() {
      this.result = "";
      try {
        const borrado = {
          sede_id: this.id,
        };
        
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=sede&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.headquarterId = event[0];
    },
    validateForm() {
      if (this.sede_nombre === "") {
        this.formError = true;
        return false;
      }
      return true;
    },
    cleanForms() {
      this.headquarter = {
        sede_id: "",
        sede_nombre: "",
      };
      this.sede_nombre = "";
      this.formError = false;
      this.loading = false;
      this.getHeadquarters();
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        this.headquarter = item;
        this.sede_nombre = item.sede_nombre;
        return;
      }
      if (item.sede_id) {
        this.id = item.sede_id;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}
</style>